import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
export interface SaveReportAsPdfs {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      survey_name: string;
      date_created: string;
      last_edited: string;
      created_by: string;
      owner: string;
      progress: string;
      user_survey_sections: Array<SurveyReportSection>;
      checkboxes?: {
        checkbox_1: boolean,
        checkbox_2: boolean
    }
    }
  }
}

export interface SurveyReportSection {
  id: number;
  user_survey_section_name: string;
  is_survey_subsection_present: boolean;
  user_survey_sub_sections: Array<SurveyReportSubSections>;
}

export interface SurveyReportSubSections {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details: Array<SurveyReportSubSectionDetail>;
}

export interface SurveyReportSubSectionDetail {
  id: number;
  field_name: string;
  field_value: string;
  field_value_multiple: string[];
  field_type: string;
  field_options: string[];
  photos: Array<MediaReportFile>;
  videos: Array<MediaReportFile>;
}
export interface MediaReportFile {
    id: number;
    filename: string;
    content_type: string;
    description: null;
    created_at: string;
    byte_size: number;
    url: string;
}

export interface ValidResponseType {
  data: object;
}

export interface ErrorPayloadType {
  key: string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  surveyName: string;
  created: string;
  lastEdited: string;
  createdBy: string;
  owner: string;
  matchId: string;
  fields: string;
  fieldValues: string;
  sectionPdfDetails: SaveReportAsPdfs;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sectionPdfDetails: SaveReportAsPdfs;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaveAsPdfController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSaveReportApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sectionPdfDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: "",
            user_survey_sections: [],
            checkboxes: {
              checkbox_1: false,
              checkbox_2: false
          }
          }
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSavePdfDetails();
  }

  handlePdfFieldsTypeFormates = (fieldSurveyDetails: SurveyReportSubSectionDetail) => {
    if (fieldSurveyDetails.field_type === "checkbox" && fieldSurveyDetails.field_value_multiple.length > 0) {
      return true
    }
    return false
  };

  handleRadioTypeFormat = (fieldRadioSurveyDetails: SurveyReportSubSectionDetail) => {
    if (fieldRadioSurveyDetails.field_type === "radio" && fieldRadioSurveyDetails.field_value) {
      return true
    }
    return false
  };

  handleSelectFormat = (fieldSelectSurveyDetails: SurveyReportSubSectionDetail) => {
    if (fieldSelectSurveyDetails.field_type === "select" && fieldSelectSurveyDetails.field_value) {
      return true
    }
    return false
  };

  handleAllFieldsTypeFormat = (fieldAllSurveyDetails: string) => {
    if (fieldAllSurveyDetails !== "checkbox" && fieldAllSurveyDetails !== "radio" && fieldAllSurveyDetails !== "select") {
      return true
    }
  };

  savePdfApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const saveHeader = {
      "Content-Type": contentType,
    };
    let requestDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(saveHeader)
    );
    requestDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestDataMessage.id, requestDataMessage);
    return requestDataMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: SaveReportAsPdfs) => {
    
    if (apiRequestCallID === this.getSaveReportApiCallId) {
     
    
      const checkbox_2 = responseJson.data.attributes.checkboxes?.checkbox_2
      const data=responseJson.data.attributes.user_survey_sections
      let sectionData=responseJson
      if(checkbox_2){
          const finalData=data.filter((column:any)=>{
            let nonEmtpyFields:any=[]
            column.user_survey_sub_sections.forEach((item:any)=>{
              item.user_survey_sub_section_details=item.user_survey_sub_section_details.filter((section:any)=>{
                return section.field_value!=="" && section.field_value!==null
              })
              nonEmtpyFields.push(item)
            })
            nonEmtpyFields=nonEmtpyFields.filter((item:any)=>item.user_survey_sub_section_details)
            column.user_survey_sub_sections=nonEmtpyFields

            return column.user_survey_sub_sections
          })
          sectionData.data.attributes.user_survey_sections=finalData
      }
    
      this.setState({
        sectionPdfDetails: sectionData
      });
    }
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  getSavePdfDetails = async () => {
    const pdfSurveyId = this.props.navigation.getParam("pdfSurveyId");    
    this.getSaveReportApiCallId = this.savePdfApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.saveReportFileAsPdfEndPoint}${pdfSurveyId}`
    });
  };

  handleDownloadReportFile = () => {
    window.print();
  };

  handleBackToDashboard = () => {
    window.history.back();
  };
  handleFormateDate = (date:string) =>{
    return moment(date).format('MM/DD/YYYY') 
   }
  // Customizable Area End
}
