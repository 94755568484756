import React from "react";
// Customizable Area Start
import {
  styled,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from "@material-ui/core";
import {
  deleteSurveyIcon,
  editSurveyIcon,
  shareSurveyIcon,
  paperSurveyIcon,
  downloadSurveyIcon
} from "./assets";
import Pagination from "@material-ui/lab/Pagination";
import CompleteDetailSurveyPopup from "./CompleteDetailSurveyPopup.web";
import { UserSurvey } from "./LandingPageController";

interface CreatedSurveyTableInterface {
  createdSurveyLists: UserSurvey;
  openSurveyDetailPopup: boolean;
  surveyPopupData: object;
  handleOpenSurveyDetail: () => void;
  handleCloseSurveyDetail: () => void;
  handleDeleteSurvey: () => void;
  handleEditSurvey: () => void;
  totalPages: number;
  handleChangePagination: () => void;
  surveyLoader: boolean;
  handleLinkShare: () => void;
  handleDownloadSurvey: () => void;
}

const MainTableBox = styled(Box)({
  marginTop: "5px",
  marginBottom: "11px"
});

const CustomTableRowHead = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #CBD5E1"
  },
  "@media(max-width: 500px)": {
    "& .MuiTableCell-root": {
      width: "60%"
    }
  }
});

const CustomTableRowBody = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "none"
  },
  "@media(max-width: 500px)": {
    "& .MuiTableCell-root": {
      padding: "9px 0px"
    }
  }
});

const CustomTableCell = styled(TableCell)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "0px",
  paddingRight: "0px",
  "@media(max-width: 1380px)": {
    fontSize: "16px"
  },
  "@media(max-width: 500px)": {
    fontSize: "16px",
    fontWeight: 500
  }
});

const CustomTableCellBody = styled(TableCell)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "0px",
  paddingRight: "0px",
  "@media(max-width: 500px)": {
    fontSize: "16px"
  },
  "@media(max-width: 350px)": {
    fontSize: "14px"
  },
  "@media(max-width: 1380px)": {
    fontSize: "16px"
  }
});

const CustomTableCellSecond = styled(TableCell)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  "@media(max-width: 1380px)": {
    fontSize: "16px"
  },
  "@media(max-width: 1180px)": {
    display: "none"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const IconBox = styled(TableCell)({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "0px",
  paddingRight: "3px",
  "@media(max-width: 1380px)": {
    display: "flex",
    gap: "4px",
    justifyContent: "unset"
  },
  "@media(max-width: 500px)": {
    justifyContent: "space-between"
  }
});

const IconImage = styled("img")({
  cursor: "pointer",
  width: "24px",
  height: "24px"
});

const PaperIconImage = styled("img")({
  display: "none",
  "@media(max-width: 1380px)": {
    display: "none",
    cursor: "pointer",
    width: "24px",
    height: "24px"
  },
  "@media(max-width: 1180px)": {
    display: "block"
  }
});

const PaginationBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "28px",
  "@media (max-width: 430px)": {
    marginBottom: "20px"
  }
});

const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-icon": {
    color: "#0F172A",
    fontSize: "30px"
  },

  "& .MuiPagination-ul": {
    "@media (max-width: 430px)": {
      flexWrap: "nowrap"
    }
  },

  "& .MuiPaginationItem-rounded": {
    color: "#181818",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    width: "37px",
    height: "37px",
    "@media(max-width: 360px)": {
      fontSize: "16px"
    }
  },

  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#0090CF",
    color: "white",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    width: "37px",
    height: "37px",
    "@media(max-width: 360px)": {
      fontSize: "16px"
    }
  }
});

const LoaderMainSection = styled(Box)({
  borderRadius: "4px",
  background: "#FFF",
  boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.15)",
  padding: "24px 25px 0px 25px",
  marginTop: "30px",
  height: "500px",
  marginBottom: "17px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const CustomCircularProgress = styled(CircularProgress)({
  color: "#7B61FD"
});

class CreatedSurveyTable extends React.Component<
  any,
  CreatedSurveyTableInterface
> {
  constructor(props: CreatedSurveyTableInterface) {
    super(props);
  }

  render() {
    const {
      surveyLoader,
      handleChangePagination,
      totalPages,
      createdSurveyLists,
      openSurveyDetailPopup,
      surveyPopupData,
      handleOpenSurveyDetail,
      handleCloseSurveyDetail,
      handleEditSurvey,
      handleDeleteSurvey,
      handleLinkShare,
      handleDownloadSurvey,
      handleFormateDate
    } = this.props;
    return (
      <MainTableBox>
        <TableContainer>
          {surveyLoader ? (
            <LoaderMainSection>
              <CustomCircularProgress color="primary" thickness={4} size={60} />
            </LoaderMainSection>
          ) : (
            <Table>
              <TableHead>
                <CustomTableRowHead>
                  <CustomTableCell>Survey name</CustomTableCell>
                  <CustomTableCellSecond>Date created</CustomTableCellSecond>
                  <CustomTableCellSecond>Last edited</CustomTableCellSecond>
                  <CustomTableCellSecond>Owner</CustomTableCellSecond>
                  <CustomTableCellSecond>Progress</CustomTableCellSecond>
                  <CustomTableCell>Action</CustomTableCell>
                </CustomTableRowHead>
              </TableHead>
              <TableBody>
                {createdSurveyLists.map((data: UserSurvey) => {
                  const surveyName = data.attributes.survey_name;
                  return (
                    <CustomTableRowBody key={`table_row_${data.id}`}>
                      <CustomTableCellBody>
                        {surveyName.length > 20
                          ? surveyName.slice(0, 50) + "...."
                          : surveyName}
                      </CustomTableCellBody>
                      <CustomTableCellSecond>
                        {handleFormateDate(data.attributes.date_created)}
                      </CustomTableCellSecond>
                      <CustomTableCellSecond>
                        {handleFormateDate(data.attributes.date_created)}
                      </CustomTableCellSecond>
                      <CustomTableCellSecond>
                        {data.attributes.owner.length > 12
                          ? data.attributes.owner.slice(0, 10) + "...."
                          : data.attributes.owner}
                      </CustomTableCellSecond>
                      <CustomTableCellSecond>
                        {data.attributes.progress}
                      </CustomTableCellSecond>
                      <IconBox>
                        <IconImage
                          src={downloadSurveyIcon}
                          alt=""
                          onClick={() => handleDownloadSurvey(data.id)}
                        />
                        <IconImage
                          src={deleteSurveyIcon}
                          alt=""
                          onClick={() => handleDeleteSurvey(data.id)}
                        />
                        <IconImage
                          src={editSurveyIcon}
                          alt=""
                          onClick={() => handleEditSurvey(data.id)}
                        />
                        <IconImage
                          src={shareSurveyIcon}
                          alt="share"
                          onClick={() => handleLinkShare(data.id)}
                        />
                        <PaperIconImage
                          src={paperSurveyIcon}
                          alt=""
                          onClick={() => handleOpenSurveyDetail(data)}
                        />
                      </IconBox>
                    </CustomTableRowBody>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {totalPages >= 2 && (
          <PaginationBox>
            <CustomPagination
              data-test-id="paginationId"
              count={totalPages}
              shape="rounded"
              onChange={handleChangePagination}
            />
          </PaginationBox>
        )}
        {openSurveyDetailPopup && (
          <CompleteDetailSurveyPopup
            handleCloseSurveyDetail={handleCloseSurveyDetail}
            data={surveyPopupData}
            openSurveyDetailPopup={openSurveyDetailPopup}
          />
        )}
      </MainTableBox>
    );
  }
}

export default CreatedSurveyTable;
// Customizable Area End
